import seguranca from '../../images/seguranca.jpg';

const Seguranca = () => {
    return(
        <section className="section section-rosa d-flex section-seguranca">
            <div className="my-auto text-lg-start">
                <h2 className="titulo-marrom-30 mb-5">SEGURANÇA</h2>
                <p>Somos a primeira farmácia da região autorizada a possuir uma cabine de manipulação de hormônios.</p>
            </div>
            <div className="my-auto mx-auto seguranca-img">
                <img className="imagem-borda-branca" src={ seguranca } alt="Imagem da cabine" />
            </div>
        </section>
    );
}

export default Seguranca;