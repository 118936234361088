import Endereco from "../../Components/Endereco";

const Contato = () => {

    const enderecos = [
        {
            cidade: "Nova Friburgo - RJ",
            logradouro: "Av. Ariosto Bento de Mello, 70",
            loja: "Lojas 2 e 3 - Centro",
            telefone: "(22) 2523-6353",
            whatsapp: "(22) 99728-1920",
            email: "atendimento@formulaexata.com.br",
            url: "https://api.whatsapp.com/send?phone=5522997281920&text=Mande%20sua%20mensagem%20para%20tirar%20dúvidas%20ou%20solicitar%20um%20orçamento!",
            mapa: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14767.341930698667!2d-42.532683!3d-22.284221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf25b87787e7b7efd!2zRsOzcm11bGEgRXhhdGEgRmFybcOhY2lhIGRlIE1hbmlwdWxhw6fDo28!5e0!3m2!1spt-BR!2sus!4v1625599362911!5m2!1spt-BR!2sus"
        },
        {
            cidade: "Cordeiro - RJ",
            logradouro: "R. Raul Veiga, Shopping Espaço 100",
            loja: "Lojas 18 e 19",
            telefone: "(22) 2551-4932",
            whatsapp: "(22) 99775-8395",
            email: "atendimentocordeiro@formulaexata.com.br",
            url: "https://api.whatsapp.com/send?phone=5522997758395&text=Mande%20sua%20mensagem%20para%20tirar%20dúvidas%20ou%20solicitar%20um%20orçamento!",
            mapa: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1849.283969335726!2d-42.362776!3d-22.027860000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1079eecbb04500d3!2sShopping%20Espa%C3%A7o%20100!5e0!3m2!1spt-BR!2sus!4v1625603956434!5m2!1spt-BR!2sus"
        }
    ]

    return (
        <section className="section section-marrom">
            <Endereco endereco={enderecos[0]} />
            <div className="espaco-tracado my-5"></div>
            <Endereco endereco={enderecos[1]} />
        </section>
    );
}

export default Contato;