import PoliticaQualidade from '../../Components/PoliticaQualidade';
import Seguranca from '../../Components/Seguranca';
import Fotos from '../../Components/Fotos';

const PoliticaDeQualidade = () => {
    return (
        <>
            <PoliticaQualidade />
            <Seguranca />
            <Fotos />
        </>
    );
}

export default PoliticaDeQualidade;