import './style.css';

import VideoPlayer from '../VideoPlayer';
import video from '../../images/video.mp4';

const EuQueFiz = () => {
    return (
        <section className="section section-marrom">
            <h2 className="titulo-rosa-30 hashtag pb-5">#euquefizFormulaExata</h2>
            <VideoPlayer video={video} />
        </section>
    );
}

export default EuQueFiz;