import FormulaExata from "../../Components/FormulaExata";
import Fornecedores from "../../Components/Fornecedores";
import EuQueFiz from "../../Components/EuQueFiz";


const Sobre = () => {
    return (
        <>
            <FormulaExata />
            <Fornecedores />
            <EuQueFiz />
        </>
    );
}

export default Sobre;