import Carrossel from '../Carrossel';

const Fornecedores = () => {
    return (
        <section className="section section-rosa">
            <h2 className="titulo-marrom-40 pb-5">FORNECEDORES</h2>
            <Carrossel />
        </section>
    );
}

export default Fornecedores;