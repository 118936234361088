import Slogan from "../../Components/Slogan";
import ImagemPrincipal from "../../Components/ImagemPrincipal";

const Home = () => {
    return (
        <>
            <ImagemPrincipal />
            <Slogan />
        </>
    );
}

export default Home;