import imagem1 from '../../images/formula-7228.jpg';
import imagem2 from '../../images/formula-7255.jpg';
import imagem3 from '../../images/formula-7300.jpg';
import imagem4 from '../../images/formula-7353.jpg';
import imagem5 from '../../images/formula-7375.jpg';

import './style.css';

const Fotos = () => {
    return (
        <section className="section-marrom py-5">
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={imagem1} className="d-block imagem-carrossel mx-auto" alt="Pesagem" />
                    </div>
                    <div className="carousel-item">
                        <img src={imagem2} className="d-block imagem-carrossel mx-auto" alt="Envelopes" />
                    </div>
                    <div className="carousel-item">
                        <img src={imagem3} className="d-block imagem-carrossel mx-auto" alt="Capsulas" />
                    </div>
                    <div className="carousel-item">
                        <img src={imagem4} className="d-block imagem-carrossel mx-auto" alt="Qualidade" />
                    </div>
                    <div className="carousel-item">
                        <img src={imagem5} className="d-block imagem-carrossel mx-auto" alt="Dermato" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
    )
}

export default Fotos;