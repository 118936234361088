import CardPremios from "../CardPremio";

const Premios = () => {

    const premios = [
        {
            titulo: 'IPP - Projeto Cliente Satisfeito',
            texto: 'Desde 2002'
        },
        {
            titulo: 'MPE Brasil',
            texto: '2009'
        },
        {
            titulo: 'Prêmio Rio Produtivo',
            texto: 'Destaque 2017 - Caso de Sucesso'
        },
        {
            titulo: 'MPE Brasil',
            texto: '2017'
        },
        {
            titulo: 'Prêmio Sebrae - Mulher de Negócio',
            texto: '2017'
        },
    ]
    return (
        <section id="premios" className="section section-marrom">
            <h2 className="titulo-branco-40">PRÊMIOS</h2>
            <div className="d-flex flex-column align-items-center py-4">
                {premios.map((premio, i) => {
                    return <CardPremios key={i} titulo={premio.titulo} texto={premio.texto} />
                })}
            </div>
        </section>
    );
}

export default Premios;