import './App.css';

import Home from './Pages/Home';
import Sobre from './Pages/Sobre';
// import Produtos from './Pages/Produtos';
import PoliticaDeQualidade from './Pages/PoliticaDeQualidade';
import Contato from './Pages/Contato';
import Navbar from './Components/Navbar';
import Iso9001 from './Components/Iso9001';
import Premios from './Components/Premios';
import Footer from './Components/Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from './Pages/NotFound';
// import { useEffect } from 'react';

function App() {

  window.addEventListener('scroll', (event) => {
    if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
      document.getElementById("back-to-top").style.display = "block";
    } else {
      document.getElementById("back-to-top").style.display = "none";
    }
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/formula-exata">
            <Sobre />
          </Route>
          {/* <Route path="/produtos">
            <Produtos />
          </Route> */}
          <Route path="/politica-qualidade">
            <PoliticaDeQualidade />
          </Route>
          <Route path="/iso9001">
            <Iso9001 />
          </Route>
          <Route path="/premios">
            <Premios />
          </Route>
          <Route path="/contato">
            <Contato />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
        <Footer />
        <a id="back-to-top" href="#" className="btn btn-lg back-to-top" role="button">
          <FontAwesomeIcon className="fa-2x" icon={faChevronUp} />
        </a>
      </BrowserRouter>
    </div>
  );
}

export default App;
