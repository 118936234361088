import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

import './style.css';

const Card = (props) => {

    return (
        <div className="cardbox">
            <div className="icon-container">
                <Icon className="fa-3x icon" icon={props.icone} />
            </div>
            <div className="card-content">
                <h5>{props.titulo}</h5>
                <p>{props.texto}</p>
            </div>
        </div>
    );
}

export default Card;