import imgNotFound from '../../images/404.png';

const NotFound = () => {
    return (
        <section className="section section-marrom">
            <h2 className="titulo-branco-30">Desculpe, a página que você está procurando não existe.</h2>
            <img className="pt-5" src={ imgNotFound } alt="404 Not Found" />
        </section>
    );
}

export default NotFound;