import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import './style.css';
import logo from '../../images/navlogo.png';
import { Link } from "react-router-dom";

const Navbar = () => {

    const urls = {
        novaFriburgo: "https://api.whatsapp.com/send?phone=5522997281920&text=Mande%20sua%20mensagem%20para%20tirar%20dúvidas%20ou%20solicitar%20um%20orçamento!",
        cordeiro: "https://api.whatsapp.com/send?phone=5522997758395&text=Mande%20sua%20mensagem%20para%20tirar%20dúvidas%20ou%20solicitar%20um%20orçamento!",
        facebook: "https://www.facebook.com/FormulaExataManipulacao",
        instagram: "https://www.instagram.com/formula_exata/"
    }

    return (

        <nav className="section-rosa navbar-expand-lg nav-main">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img className="py-2 logo-img" src={logo} alt="Logomarca" />
                </Link>
            </div>
            <button className="navbar-toggler mb-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <FontAwesomeIcon className="navbar-toggler-icon fa-2x wpp" icon={faBars} />
            </button>
            <div className="justify-content-center navbar-collapse collapse" id="navbarNav">
                <ul className="nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/formula-exata">Fórmula Exata</Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/produtos">Produtos</Link>
                    </li> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="/politica-qualidade">Política de Qualidade</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/iso9001">ISO 9001</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/premios">Prêmios</Link>
                    </li>
                    {/* <li className="nav-item">
                    <Link className="nav-link" to="/blog">Blog</Link>
                </li> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="/contato">Contato</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <div className="dropdown">
                            {/* <a className="nav-link nav-wpp" href="">
                            <FontAwesomeIcon className="fa-2x wpp mx-1" icon={faWhatsapp} />
                        </a> */}
                            <button className="nav-link nav-wpp m-auto" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon className="fa-2x wpp mx-1" icon={faWhatsapp} />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li><a className="dropdown-item" target="_blank" rel="noreferrer" href={urls.novaFriburgo}>Nova Friburgo</a></li>
                                <li><a className="dropdown-item" target="_blank" rel="noreferrer" href={urls.cordeiro}>Cordeiro</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link nav-wpp" target="_blank" rel="noreferrer" href={urls.facebook}>
                            <FontAwesomeIcon className="fa-2x wpp mx-1" icon={faFacebook} />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link nav-wpp" target="_blank" rel="noreferrer" href={urls.instagram}>
                            <FontAwesomeIcon className="fa-2x wpp mx-1" icon={faInstagram} />
                        </a>
                    </li>
                </ul>
            </div>

        </nav>
    );
}

export default Navbar;