import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

import './style.css';

const CardPremios = (props) => {
    return (
        <div className="cardbox-premio d-flex my-5">
            <div className="premio-icon-container d-flex">
                <FontAwesomeIcon className="fa-5x icon icon-premio mx-auto" icon={faTrophy} />
            </div>
            <div className="premio-card-content d-flex text-start justify-content-center flex-column px-5">
                <h3 className="titulo-premio">{props.titulo}</h3>
                <p className="texto-premio">{props.texto}</p>
            </div>
        </div>
    );
}

export default CardPremios;