import './style.css';
import estudiofalk from '../../images/logomarca-estudiofalk.png';

const Footer = () => {
    return (
        <footer className="section-rosa footer py-4">
            <span>2021 Fórmula Exata | Desenvolvido por </span>
            <a target="_blank" rel="noreferrer" href="http://estudiofalk.com">
                <img className="logo-falk mb-2" src={estudiofalk} alt="Estúdio Falk" />
            </a>
        </footer>
    )
}

export default Footer;