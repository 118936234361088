import iso9001 from '../../images/iso9001.jpg';
import './style.css';

const Iso9001 = () => {
    return (
        <section id="iso9001" className="section section-marrom section-iso d-flex">
            <div className="my-auto">
                <img className="iso-img" src={iso9001} alt="Padrão ISO 9001" />
            </div>
            <div className="my-auto text-start text-iso-div">
                <h2 className="titulo-branco-30 mb-5">A ÚNICA COM ISO 9001</h2>
                <p className="pb-4">A Fórmula Exata é a única farmácia de manipulação de Nova Friburgo e região com ISO 9001. Certificação concedida e renovada anualmente desde 2006, através de auditorias periódicas.</p>
                <p className="pb-4">Você sabe a importância da certificação ISO 9001? Com ela, implementamos padrões internacionais, nos nossos produtos, atendimento e em toda nossa gestão. </p>
                <p className="pb-4">Seguimos todos os protocolos exigidos pela norma internacional, ISO 9001, com testes frequentes e matérias-primas confiáveis. Além de toda higienização e proteção de nossos espaços e colaboradores.</p>
            </div>
        </section>
    );
}

export default Iso9001;