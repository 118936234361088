import image from '../../images/image.jpg';
import VideoPlayer from '../VideoPlayer';
import video from '../../images/formulavideo.mp4';
import './style.css';
import thumbImg from '../../images/thumbVideoSeguranca.png';

const PoliticaQualidade = () => {
    return (
        <section id="politica-qualidade" className="section section-marrom bg-flores">
            <h2 className="pb-5 titulo-branco-40">POLÍTICA DE QUALIDADE</h2>
            <p className="pb-4">A Fórmula Exata, farmácia de manipulação de fórmulas alopáticas, fitoterápicas e cosméticas tem como política da qualidade atender aos compromissos assumidos com nossos clientes, por meio da melhoria contínua dos processos e do treinamento dos funcionários, de forma a aprimorar a manipulação de nossos produtos e do atendimento às exigências do sistema de gestão da qualidade.</p>
            <h2 className="py-5 titulo-branco-30">CONTROLE DE QUALIDADE</h2>
            <p className="pb-4">Nossa matéria-prima passa por rigoroso controle de qualidade, além da procedência dos melhores fornecedores do mercado. Testes de resistência, durabilidade, pH e vedação de embalagens.</p>
            <p className="pb-4">Nossos manipulados são enviados a Fragon Lab, empresa responsável por auditorias mensais e controle de qualidade de produtos acabados. Identificação do manipulado, determinação de peso, doseamento, uniformidade do conteúdo, perfil de impurezas e metais pesados são alguns dos testes realizados.</p>
            <img className="round-img imageShrink mb-5" height="100px" src={image} alt="Padrão FE" />
            <VideoPlayer video={video} thumb={thumbImg} />
        </section>
    ); 
}

export default PoliticaQualidade;