import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, useEffect } from 'react';
import './style.css';

const usePlayerState = ($videoPlayer) => {
    const [playerState, setPlayerState] = useState({
        playing: false,
        percentage: 0
    });

    useEffect(() => {
        playerState.playing ? $videoPlayer.current.play() : $videoPlayer.current.pause();
    });

    const toggleVideoPlay = () => {
        setPlayerState({
            ...playerState,
            playing: !playerState.playing
        });
    }

    const handleTimeUpdate = () => {
        const currentPercentage = ($videoPlayer.current.currentTime / $videoPlayer.current.duration) * 100;
        setPlayerState({
            ...playerState,
            percentage: currentPercentage
        })
    }

    const handleChangeVideoPercentage = (event) => {
        console.log(event.target.value);

        const currentPercentageValue = event.target.value;
        $videoPlayer.current.currentTime = ($videoPlayer.current.duration / 100) * currentPercentageValue;
        setPlayerState({
            ...playerState,
            percentage: currentPercentageValue
        })
    }

    const handleSpeedChange = (event) => {
        console.log(event.target.value);
    }

    return{
        playerState,
        toggleVideoPlay,
        handleTimeUpdate,
        handleChangeVideoPercentage,
        handleSpeedChange
    }
}


const VideoPlayer = ({video, thumb}) => {

    const $videoPlayer = useRef(null);

    const {
        playerState,
        toggleVideoPlay,
        handleTimeUpdate,
        handleChangeVideoPercentage
    } = usePlayerState($videoPlayer);

    return (
        <div className="videoWrapper">
            <video onClick={toggleVideoPlay} onTimeUpdate={handleTimeUpdate} ref={$videoPlayer} className="video-borda-rosa" poster={thumb} src={video} />
            <div className="controls py-3">
                <button className="play-pause-btn" onClick={toggleVideoPlay}>
                    { playerState.playing ? <FontAwesomeIcon icon={faPause}/> : <FontAwesomeIcon icon={faPlay}/>}
                </button>
                <input className="percentage-bar" type="range" min="0" max="100" onChange={handleChangeVideoPercentage} value={playerState.percentage} />
            </div>
        </div>
    );
}

export default VideoPlayer;