import Card from "../Card";
import './style.css';

import { faStar, faListAlt } from "@fortawesome/free-regular-svg-icons";
import { faBalanceScale, faChartBar } from "@fortawesome/free-solid-svg-icons";
import loja1 from '../../images/loja-1.png';
import loja2 from '../../images/loja2-2.png';
import loja3 from '../../images/loja3.jpeg';
import loja4 from '../../images/loja4.jpeg';

const FormulaExata = () => {

    const cards = [{
        texto: "Fornecer medicamentos manipulados e personalizados que garantam a satisfação dos clientes.",
        titulo: "MISSÃO",
        icone: faListAlt
    },
    {
        texto: "Buscamos preparar a Fórmula Exata como organização inovadora, direcionando esforços para sua contínua ampliação sempre com ética e excelência empresarial.",
        titulo: "VISÃO",
        icone: faChartBar
    },
    {
        texto: "Ética na manifestação dos valores morais, no qual o farmacêutico atua em benefício do ser humano e do meio ambiente.",
        titulo: "VALORES: ÉTICA",
        icone: faBalanceScale
    },
    {
        texto: "Qualidade nas manipulações e compromisso com os prazos de entrega.",
        titulo: "VALORES: QUALIDADE E COMPROMISSO",
        icone: faStar
    }]

    return (
        <section id="formula-exata" className="section section-marrom">
            <h2 className="titulo-branco-40 pb-5">Sobre nós</h2>
            <div className="d-flex justify-content-center cards-main">
                {cards.map((card, i) => {
                    return <Card key={i} texto={card.texto} titulo={card.titulo} icone={card.icone} />
                })}
            </div>
            <h3 className="titulo-branco-30 pt-5">Matriz Nova Friburgo</h3>
            <div className="d-flex justify-content-center mt-5 lojas-img-container">
                <img className="imagem-borda-branca mx-3" src={loja2} alt="loja1" />
                <img className="imagem-borda-branca mx-3" src={loja1} alt="loja2" />
            </div>
            <h3 className="titulo-branco-30 pt-5">Filial Cordeiro</h3>
            <div className="d-flex justify-content-center mt-5 lojas-img-container">
                <img className="imagem-borda-branca mx-3" src={loja4} alt="loja4" />
                <img className="imagem-borda-branca mx-3" src={loja3} alt="loja3" />
            </div>
        </section>
    );
}

export default FormulaExata;