import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCapsules } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import './style.css';

const Endereco = ({ endereco, divClass }) => {
    return (
        <div className="endereco-container d-flex">
            <div className="card-endereco mx-auto">
                <FontAwesomeIcon className="fa-2x pills-icon my-3" icon={faCapsules} />
                <h2 className="titulo-cidade">{endereco.cidade}</h2>
                <p className="texto-cidade">{endereco.logradouro}</p>
                <p className="texto-cidade">{endereco.loja}</p>
                <p className="texto-cidade">{endereco.email}</p>
                <p className="texto-cidade">{endereco.telefone}</p>
                <a className="btn-link-whats" target="_blank" rel="noreferrer" href={endereco.url}>
                    <button className="btnShrink btnWhats justify-content-center my-3 d-flex">
                        <FontAwesomeIcon className="fa-1x btn-icon" icon={faWhatsapp} />
                        <span className="texto-btn">{endereco.whatsapp}</span>
                    </button>
                </a>
            </div>
            <div className="mapa">
                <iframe title="map" className="map-frame" src={endereco.mapa} loading="lazy"></iframe>
            </div>
        </div>
    );
}

export default Endereco;