import activepharmaceutica from "../../images/activepharmaceutica.jpg";
import aqia from "../../images/aqia.jpg";
import embrafarma from "../../images/embrafarma.jpg";
import fagron from "../../images/fagron.jpg";
import florien from "../../images/florien.jpg";
import galena from "../../images/galena.jpg";
import gamma from "../../images/gamma.jpg";
import iberoquimica from "../../images/iberoquimica.jpg";
import infinitypharma from "../../images/infinitypharma.jpg";
import labsynth from "../../images/labsynth.jpg";
import lemma from "../../images/lemma.jpg";
import levviale from "../../images/levviale.jpg";
import purifarma from "../../images/purifarma.jpg";
import valequimica from "../../images/valequimica.jpg";
import vepakum from "../../images/vepakum.jpg";

import './style.css';


const Carrossel = () => {
    return (
        <div id="carouselIndicators" className="carousel slide" data-bs-ride="carousel">
            
            <div className="carousel-inner d-flex">
                <div className="carousel-item active">
                    <img src={activepharmaceutica} className="carousel-image mb-5" alt="activepharmaceutica" />
                    <img src={aqia} className="carousel-image mb-5" alt="aqia" />
                    <img src={embrafarma} className="carousel-image mb-5" alt="embrafarma" />
                </div>
                <div className="carousel-item">
                    <img src={fagron} className="carousel-image mb-5" alt="fagron" />
                    <img src={florien} className="carousel-image mb-5" alt="florien" />
                    <img src={galena} className="carousel-image mb-5" alt="galena" />
                </div>
                <div className="carousel-item">
                    <img src={gamma} className="carousel-image mb-5" alt="gamma" />
                    <img src={iberoquimica} className="carousel-image mb-5" alt="iberoquimica" />
                    <img src={infinitypharma} className="carousel-image mb-5" alt="infinitypharma" />
                </div>
                <div className="carousel-item">
                    <img src={labsynth} className="carousel-image mb-5" alt="labsynth" />
                    <img src={lemma} className="carousel-image mb-5" alt="lemma" />
                    <img src={levviale} className="carousel-image mb-5" alt="levviale" />
                </div>
                <div className="carousel-item">
                    <img src={purifarma} className="carousel-image mb-5" alt="purifarma" />
                    <img src={valequimica} className="carousel-image mb-5" alt="valequimica" />
                    <img src={vepakum} className="carousel-image mb-5" alt="vepakum" />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            <div className="carousel-indicators position-relative">
                <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
            </div>
        </div>
    );
}

export default Carrossel;